var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "header-actions": _vm.headerActions,
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "toolbar-actions": _vm.toolbarActions,
        widget: _vm.widget,
        "title-icon": "mdi-account-multiple",
        "initial-width": "4",
      },
      on: {
        "toolbar-action": function ($event) {
          return _vm.toolbarMethod($event)
        },
      },
    },
    [
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "reload-counter": _vm.reloadCounter,
          "row-actions": _vm.rowActions,
          "row-method": _vm.rowMethod,
          "search-method": _vm.search,
          criteria: _vm.criteria,
          "export-counter": _vm.exportCounter,
          "export-file-name": "user-accounts",
          "sort-by": ["documentUpdatedAt"],
          "sort-desc": ["documentUpdatedAt"],
        },
        on: {
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.group.name",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item["group.name"]) +
                    " (" +
                    _vm._s(item["group.code"]) +
                    ") "
                ),
              ]
            },
          },
          {
            key: "item.profile.firstName",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  _vm._s(
                    item["profile.firstName"]
                      ? item["profile.firstName"]
                      : "..."
                  )
                ),
              ]
            },
          },
          {
            key: "item.profile.lastName",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item["profile.lastName"]
                        ? item["profile.lastName"]
                        : "..."
                    )
                ),
              ]
            },
          },
          {
            key: "item.status",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      color: item["status"] === "ACTIVE" ? "green" : "red",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.ae$EnumToString(item["status"])) + " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.documentStatus",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      color:
                        item["documentStatus"] === "MODIFIED" ||
                        item["documentStatus"] === "REGISTERED"
                          ? "yellow"
                          : item["documentStatus"] === "CREATED"
                          ? "green"
                          : "red",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.ae$EnumToString(item["documentStatus"])) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.documentUpdatedAt",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.ae$formatDateTime(item["documentUpdatedAt"])) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "bottom-space" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }